<template>
  <v-app>
    <!-- navbar ici -->
    <AppBar v-if="$session.exists()" />
    <!-- contenu dans main -->
    
    <v-main>
      <!-- router affiche les views définis dans router.js -->
      <router-view />

      <Dialog v-if="$session.exists()" />
    </v-main>
    <Footer :session-exist="sessionExist" />
  </v-app>
</template>

<script>
import Dialog from './components/Dialog'
import Footer from './components/Footer'
import AppBar from './components/AppBar'
// définir les données, les fonctions, etc ...
export default {
  name: 'App',
  components : {
    Footer,
    Dialog,
    AppBar
  },
  data() {
    return {
      sessionExist : false,
    }
  },
  created() {
    this.sessionExist = this.$session.exists();
  },
  updated() {
    this.sessionExist = this.$session.exists();
  },
};
</script>

<style scoped>
/**
  Styles du composant
*/
</style>
