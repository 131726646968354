<template>
  <v-container class="my-3 layoutSize">
    <div
      class="px-1 text-uppercase font-weight-bold"
      style="font-size:30pt"
    >
      Mon compte
    </div>
    <v-divider
      class="ml-1 mb-3"
      style="width: 100px;border:1px solid red; border-radius: 5px"
      color="cyan"
      inset
    />
    <v-card
      class="mb-5"
      elevation="6"
    >
      <v-card-text class="my-0 pb-0">
        Nom
        <v-text-field
          v-model="lastname"
          class="pt-0 mb-0"
        />
      </v-card-text>
      <v-card-text class="my-0 py-0">
        Prénom
        <v-text-field
          v-model="firstname"
          class="pt-0 mb-0"
        />
      </v-card-text>
      <v-card-text class="my-0 py-0">
        Mail
        <v-text-field
          v-model="mail"
          class="pt-0 mb-0"
        />
      </v-card-text>
      <v-card-text class="my-0 py-0">
        Adresse
        <v-text-field
          v-model="adresse"
          class="pt-0 mb-0"
        />
      </v-card-text>
      <v-card-text class="my-0 py-0">
        Date de naissance
        <v-text-field
          v-model="date_naissance"
          class="pt-0 mb-0"
        />
      </v-card-text>
    </v-card>
    <v-btn
      color="red"
      dark
      fab
      class="mr-4 text-overline"
      @click="signOut"
    >
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
import { signOut } from '../http/authentication'
import { getAccount } from '../http/account'


export default {
    name : 'Account',
    data() {
        return {
            firstname : '',
            lastname : '',
            mail : '',
            adresse : '',
            date_naissance : ''
        }
    },
    created() {
        this.getAccount();
    },
    methods: {
        async getAccount() {
            await getAccount(this.$session.get('ID_utilisateur'))
                .then(data => {
                    console.log(data);
                    this.firstname = data.prenom
                    this.lastname = data.nom
                    this.mail = data.mail
                    this.adresse = data.adresse
                    this.date_naissance = this.getFullDate(data.date_naissance)
                })
        },
        async signOut() {
            await signOut()
            .then(() => {
                this.$session.destroy();
                this.$router.push({ name : 'Login' })
            })
            .catch(err => {
                this.$session.destroy();
                this.$router.push({ name : 'Login' })
                console.log(err.message)
            });
        },
        getFullDate(date) {
            // Set string date to Datetime dd/mm/yyyy
            let newdate = new Date(date);
            const day = newdate.getDate() < 10 ? `0${newdate.getDate()}` : newdate.getDate();
            const month = newdate.getMonth() + 1 < 10 ? `0${newdate.getMonth() + 1}` : newdate.getMonth() + 1;
            const year = newdate.getFullYear();
            const FullDate = `${day}/${month}/${year}`;
            return FullDate;
        },
    },
}
</script>

<style lang="scss" scoped>
    .layoutSize {
        @media screen and (min-width: 960px) {
            width: 100%;
        }
        @media screen and (min-width: 1260px) {
            width: 50%;
        }
    }
</style>