<template>
  <div>
    <v-card class="pt-2">
      <div class="d-flex pa-5">
        <div>
          <div class="text-uppercase font-weight-medium">
            Nom prénom
          </div>
          <div class="text-uppercase font-weight-medium">
            Email
          </div>
          <div class="mt-6">
            <v-btn
              color="primary"
              small
            >
              S'abonner
              <v-icon small>
                mdi-plus
              </v-icon>
            </v-btn>
            <v-btn
              icon
              class="ml-2"
              to="/Account"
            >
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </div>
        </div>
                
        <v-spacer />
        <v-avatar
          color="red"
          size="100"
          style="border-radius:100px"
        >
          <span class="white--text headline">CJ</span>
        </v-avatar>
      </div>
            
      <v-tabs show-arrows>
        <v-tab>Ressources</v-tab>
        <v-tab>Profil</v-tab>
        <v-tab>Collaborateur</v-tab>
      </v-tabs>
    </v-card>
  </div>
</template>
<script>
export default {
    name : 'Profile'    
}
</script>