<template>
  <v-container
    class="d-flex layoutSize"
    style="height:100%;flex-flow: column;"
  >
    <div style="flex: 0 1 auto;">
      <div
        class="px-4 pt-3 text-uppercase font-weight-bold"
        style="font-size:30pt"
      >
        {{ folder }}
      </div>
      <v-divider
        class="ml-4"
        style="width: 100px;border:1px solid red; border-radius: 5px"
        color="cyan"
        inset
      />
    </div>
    <template v-if="resources.length > 0">
      <div class="my-2 ml-4">
        {{ resources.length }} document<span v-if="resources.length > 1">s</span>
      </div>
      <div class="d-flex ml-4">
        <v-btn-toggle
          dense
          background-color="yellow"
        >
          <v-btn
            small
            @click="sortRessource('nameAsc')"
          >
            <v-icon>mdi-sort-alphabetical-ascending</v-icon>
          </v-btn>
          <v-btn
            small
            @click="sortRessource('nameDesc')"
          >
            <v-icon>mdi-sort-alphabetical-descending</v-icon>
          </v-btn>
          <v-btn
            small
            @click="sortRessource('dateAsc')"
          > 
            <v-icon>mdi-sort-calendar-ascending</v-icon>
          </v-btn>
          <v-btn
            small
            @click="sortRessource('dateDesc')"
          >
            <v-icon>mdi-sort-calendar-descending</v-icon>
          </v-btn>
        </v-btn-toggle>
        <v-dialog
          v-if="selected != null"
          fullscreen
          scrollable
          :value="true"
          opacity="0.85"
          z-index="3"
          color="black"
          style="border-radius:0px;height:100%"
        >
          <v-card tile>
            <v-toolbar
              flat
              dense
              dark
              color="indigo"
              style="flex:0"
            >
              <v-toolbar-title>Modifier</v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-btn
                  icon
                  @click="selected=null"
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <div
              class="px-5 pt-5 font-weight-bold"
              style="font-size:25pt"
            >
              {{ resources[selected].Nom_ressource }}
            </div>
            <div
              v-if="resources[selected].Description_ressource"
              class="px-5 pt-5"
            >
              {{ resources[selected].Description_ressource }}
            </div>
            <div class="d-flex justify-center">
              <template v-if="loadfile">
                <v-sheet
                  width="100%"
                  height="250"
                  color="#DEDEDE"
                  class="d-flex justify-center my-8"
                >
                  <v-progress-circular
                    class="align-self-center"
                    indeterminate
                    color="primary"
                  />
                </v-sheet>
              </template>
              <template v-else>
                <img
                  v-if="resources[selected].file && mimeType == 'img'"
                  class="my-9 imagePreviewMaxSize"
                  style="max-width:100%"
                  :src="resources[selected].file"
                  alt=""
                >
                <iframe
                  v-else-if="resources[selected].file && mimeType == 'pdf'"
                  :src="resources[selected].file"
                  class="PDFPreviewMaxSize"
                  type="application/pdf"
                />
                <video
                  v-else-if="resources[selected].file && mimeType == 'video'"
                  style="max-width:100%"
                  class="imagePreviewMaxSize"
                  controls
                >
                  <source
                    :src="resources[selected].file"
                    type="video/mp4"
                  >
                </video>
                <v-sheet
                  v-else
                  width="100%"
                  height="200"
                  color="#DEDEDE"
                />
              </template>
            </div>
              
            <div class="d-flex justify-center mr-2 mt-2">
              <v-btn
                class="mr-1"
                icon
                @click="dialogEdit = true"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                class="mr-1"
                icon
              >
                <a
                  :download="resources[selected].Nom_ressource"
                  :href="resources[selected].file"
                  style="text-decoration:none;color:gray"
                >
                  <v-icon>mdi-download</v-icon>
                </a>
              </v-btn>
            </div>

    
            <v-dialog
              fullscreen
              :value="dialogEdit"
              scrollable
              style="z-index:15"
              transition="dialog-bottom-transition"
            >
              <v-card tile>
                <v-toolbar
                  flat
                  dense
                  dark
                  color="indigo"
                  style="flex:0"
                >
                  <v-toolbar-title>Modifier</v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-btn
                      icon
                      @click="dialogEdit = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <v-card-text class="my-4">
                  <v-text-field
                    counter="255"
                    label="Nom"
                    filled
                    :value="resources[selected].Nom_ressource"
                  />
                  <v-textarea
                    counter="255"
                    label="Description"
                    filled
                    :value="resources[selected].Description_ressource"
                  />
                  <v-select
                    filled
                    :items="categories"
                    label="Catégorie"
                    item-text="libelle"
                    item-value="ID_categorie"
                    :value="{ID_categorie : resources[selected].ID_categorie}"
                  />
                  <v-checkbox
                    label="Public ?"
                    :value="resources[selected].isPublic"
                  />
                  <v-select
                    filled
                    label="Partager"
                    :items="groups"
                    item-text="libelle"
                    item-value="ID_groupe"
                    multiple
                  />
                  <v-btn color="success">
                    Enregistrer
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card>
        </v-dialog>
      </div>
      <v-list>
        <v-divider />
        <template v-for="(resource, index) in resources">
          <v-list-item
            :key="'Re-'+resource.ID_ressource"
            link
            single-line
            @click="select(index)"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ resource.Nom_ressource }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action
              class="d-flex"
              style="flex-direction:row; align-items:center"
            >
              <div> 
                {{ getFullDate(resource.date_upload) }}
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="'Div-'+resource.ID_ressource" />
        </template>
      </v-list>
    </template>
    <template v-else>
      <div
        class="d-flex"
        style="flex: 1 1 auto;align-items:center"
      >
        <div
          class="text-center align-self-center"
          style="width:100%;flex: 1 1 auto;"
        >
          Le dossier est vide
        </div>
      </div>
    </template>
  </v-container>
</template>

<script>
import { getCategories } from '../http/categories';
import { getResources, getFiles } from '../http/resources';
import { getGroups } from '../http/groups'

export default {
    name : 'Files',
    data() {
      return {
        resources : [],
        folder : '',
        filterFolder : '',
        selected : null,
        loadfile : false,
        dialogEdit : false,
        groups : [],
        categories : [],
        mimeType : ''
      }
    },
    created() {
      switch(this.$route.params.folderName) {
        case 'Public':
          this.folder = 'Public';
          this.filterFolder = "public"
          break;
        case 'Private' :
          this.folder = "Privé";
          this.filterFolder = "private"
          break;
        case 'Share' :
          this.folder = "Partagé";
          this.filterFolder = "share"
          break;
        case 'ShareWithMe' :
          this.folder = "Partagé avec moi";
          this.filterFolder = "sharewithme"
          break;
      }

      this.getResources();
      this.getGroups();
      this.getCategories();
    },
    methods: {
      async getResources() {
        await getResources(this.filterFolder)
          .then((data) => {
            this.resources = data
            })
          .catch(err => {
              throw err
          })
      },
      getFullDate(date) {
        let newdate = new Date(date);
        const day = newdate.getDate() < 10 ? `0${newdate.getDate()}` : newdate.getDate();
        const month = newdate.getMonth() + 1 < 10 ? `0${newdate.getMonth() + 1}` : newdate.getMonth() + 1;
        const year = newdate.getFullYear();
        const FullDate = `${day}/${month}/${year}`;
        return FullDate;
      },
      sortRessource(filter) {
        switch(filter) {
          case 'dateAsc' :
            this.resources.sort((a, b) => new Date(a.date_upload) - new Date(b.date_upload)); 
            break;
          case 'dateDesc' : 
            this.resources.sort((a, b) => new Date(b.date_upload) - new Date(a.date_upload));
            break;
          case 'nameAsc' : 
            this.resources.sort((a, b) => a.Nom_ressource.localeCompare(b.Nom_ressource));
            break;
          case 'nameDesc' : 
            this.resources.sort((a, b) => b.Nom_ressource.localeCompare(a.Nom_ressource));
            break;
        }
      },
      async getResourceFile(id) {
            this.loadfile = true
            await getFiles(id)
              .then((res) => {
                return this.resources[this.selected].file = `data:${this.resources[this.selected].typeRessource};base64,${res.data}`;
              })
              .catch(err => {
                throw err
              }) 
              this.loadfile = false
        },
        async select(index) {
            this.selected = index;
            this.getMimeType(this.resources[this.selected].typeRessource);
            if (this.resources[this.selected].file) return;
            return await this.getResourceFile(this.resources[this.selected].ID_ressource);
        },
        async getCategories() {
          await getCategories()
            .then((data) => {
              return this.categories = data;
            })
            .catch(err => {
              throw err
            }) 
        },
        async getGroups() {
          await getGroups()
            .then((data) => this.groups = data)
            .catch(err => {
              throw err
            }) 
        },
        getMimeType(str) {
            if (str.search(/\b(\w*pdf\w*)|(\w*PDF\w*)|(\w*application\/pdf\w*)\b/g) !== -1) this.mimeType = 'pdf'
            if (str.search(/\b(\w*image\w*)|(\w*jpg\w*)|(\w*png\w*)|(\w*jpeg\w*)|(\w*gif\w*)\b/g) !== -1) this.mimeType = 'img'
            if (str.search(/\b(\w*avi\w*)|(\w*mp4\w*)|(\w*video\w*)\b/g) !== -1) this.mimeType = 'video'
        }
    },
}
</script>

<style lang="scss">
  .v-overlay__content {
    width : 100%;
  }
</style>

<style lang="scss" scoped>
    .layoutSize {
        @media screen and (min-width: 960px) {
            width: 100%;
        }
        @media screen and (min-width: 1260px) {
            width: 50%;
        }
    }

    .imagePreviewMaxSize {
        @media screen and (min-width: 150px) {
            max-height: 300px !important;
        }
        @media screen and (min-width: 960px) {
            max-height: 400px !important;
        }
        @media screen and (min-width: 1260px) {
            max-height: 600px !important;
        }
    }
    
    .PDFPreviewMaxSize {
        @media screen and (min-width: 150px) {
            height: 450px !important;
            width: 100%;
        }
        @media screen and (min-width: 960px) {
            height: 550px !important;
            width: 80%;
        }
        @media screen and (min-width: 1260px) {
            height: 600px !important;
            width: 60%;
        }
    }
</style>