import Vue from 'vue';
import axios from 'axios';

const base_url = "http://localhost:3000/api/authentication";

export const getToken = () => {
    try {
        const token = Vue.prototype.$session.get('auth_token');
        if (!token) throw new Error();
        return token;
    } catch (err) {
        console.log(err);
        return false
    }
}

/**
 * Authenticate user
 * 
 * @param {String} email
 * @param {String} password
 * @returns 
 */
export function signIn(email, password) {
    return new Promise((resolve, reject) => {
        try {
            axios.request({ method : 'post', url : `${base_url}/signIn`, data : { email , password } })
                    .then((response) => {
                        if (response.status !== 200 || !('token' in response.data)) reject(new Error('Incorrect login or password.'));
                        resolve(response);
                    })
                    .catch((err) => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Disconnect user
 * 
 * @returns 
 */
export function signOut() {
    return new Promise((resolve, reject) => {
        try {
            axios.request({ method : 'POST', url : base_url + '/signOut', headers : { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` } })
                    .then(response => {
                        if (response.status !== 200) reject(new Error(`Error (${response.status}) during signing out`));
                        resolve(response);
                    })
                    .catch(err => {
                        reject(err)
                    });
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Create user
 * 
 * @param {String} firstname 
 * @param {String} lastname 
 * @param {String} email 
 * @param {String} password 
 * @param {String} confirm_password
 * @returns 
 */
export function singUp(firstname, lastname, email, password, confirm_password) {
    return new Promise((resolve, reject) => {
        try {
            if (!firstname || !lastname || !email || !password || !confirm_password) reject(new Error('Please complete all fields.'));
            if (password !== confirm_password) reject(new Error('Passwords do not match'));
            if (password.length < 6) reject(new Error('Password must contain more than 6 characters'));
            axios.post(`${base_url}/signUp`, {
                    firstname,
                    lastname,
                    email,
                    password
                }).then((response) => {
                    if (response.status !== 200 || !(response.data)) reject(new Error('This email is already registered'));
                    resolve(response);
                }).catch((err) => reject(err));
        } catch (err) {
            reject(err)
        }
    });   
}