<template>
  <v-container
    class="mobilefirst"
    fluid
  >
    <!-- <div class="px-4 mt-3 text-uppercase font-weight-bold" style="font-size:30pt">Nouveau compte</div>
      <v-divider class="ml-4 mb-3" style="width: 100px;border:1px solid red; border-radius: 5px" color="cyan" inset></v-divider>

  <validation-observer
    ref="observer"
    v-slot="{ invalid }"
  >
    <form @submit.prevent="submit">
      <validation-provider
        v-slot="{ errors }"
        name="Name"
        rules="required|max:10"
      >
        <v-text-field
          v-model="name"
          :counter="20"
          :error-messages="errors"
          label="Nom"
          required
        ></v-text-field>
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        name="Firstname"
        rules="required|max:10"
      >
        <v-text-field
          v-model="firstname"
          :counter="20"
          :error-messages="errors"
          label="Prénom"
          required
        ></v-text-field>
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        name="Adress"
        rules="required|max:50"
      >
        <v-text-field
          v-model="adress"
          :counter="20"
          :error-messages="errors"
          label="Adresse"
          required
        ></v-text-field>
      </validation-provider>
      <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        label="Birthday date"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      v-model="date"
      :max="new Date().toISOString().substr(0, 10)"
      min="1950-01-01"
      @change="save"
    ></v-date-picker>
  </v-menu>
      <validation-provider
        v-slot="{ errors }"
        name="email"
        rules="required|email"
      >
        <v-text-field
          v-model="email"
          :error-messages="errors"
          label="E-mail"
          required
        ></v-text-field>
      </validation-provider>

      <v-btn
        class="mr-4 success"
        type="submit"
        :disabled="invalid"
      >
        S'inscrire
      </v-btn>
      <v-btn color="primary" to="/Login">
        Se connecter
      </v-btn>
    </form>
  </validation-observer> -->
  </v-container>
</template>

<script>
  // import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
  // import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

  // setInteractionMode('eager')

  // extend('digits', {
  //   ...digits,
  //   message: '{_field_} needs to be {length} digits. ({_value_})',
  // })

  // extend('required', {
  //   ...required,
  //   message: '{_field_} can not be empty',
  // })

  // extend('max', {
  //   ...max,
  //   message: '{_field_} may not be greater than {length} characters',
  // })

  // extend('regex', {
  //   ...regex,
  //   message: '{_field_} {_value_} does not match {regex}',
  // })

  // extend('email', {
  //   ...email,
  //   message: 'Email must be valid',
  // })

  export default {
    name : 'Register',
    // components: {
    //   ValidationProvider,
    //   ValidationObserver,
    // },
    // data: () => ({
    //   date: null,
    //   menu: false,
    // }),
    // watch: {
    //   menu (val) {
    //     val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    //   },
    // },
    // methods: {
    //   save (date) {
    //     this.$refs.menu.save(date)
    //   },
    //   submit () {
    //     this.$refs.observer.validate()
    //   },
    // },
  }
</script>

<style lang="scss" scoped>
    .mobilefirst {
        
        @media screen and (min-width: 960px) {
            width: 100%;
        }
        @media screen and (min-width: 1260px) {
            width: 50%;
        }

    }
</style>