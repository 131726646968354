<template>
  <v-card
    class="my-3 mx-0"
    outlined
    style="border : 2px solid #999999"
  >
    <template v-if="loadfile">
      <v-sheet
        width="100%"
        height="200"
        color="#DEDEDE"
        class="d-flex justify-center"
      >
        <v-progress-circular
          class="align-self-center"
          indeterminate
          color="primary"
        />
      </v-sheet>
    </template>
    <template v-else>
      <img
        v-if="mimeType == 'img'"
        width="100%"
        :src="file"
        @click="overlayImg = true"
      >
      <iframe
        v-if="mimeType == 'pdf'"
        :src="file"
        width="100%"
        height="500px"
        type="application/pdf"
      />
      <video
        v-else-if="file && mimeType == 'video'"
        width="100%"
        controls
      >
        <source
          :src="file"
          type="video/mp4"
        >
      </video>
      <v-btn
        v-if="mimeType == 'pdf' || mimeType == 'video'"
        color="indigo"
        dark
        class="ml-3 my-1"
        small
        @click="overlayImg = true"
      >
        voir plus 
      </v-btn>
      <v-fade-transition>
        <v-dialog
          fullscreen
          scrollable
          :value="overlayImg"
          opacity="0.95"
          z-index="3"
          color="black"
          style="border-radius: 0px"
        >
          <v-card tile>
            <v-toolbar
              flat
              dense
              dark
              color="indigo"
              style="flex:0"
            >
              <v-toolbar-title>Visualiser</v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-btn
                  icon
                  class="mr-4"
                  @click="overlayImg=false"
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <div
              class="px-5 pt-5 font-weight-bold"
              style="font-size:25pt"
            >
              {{ title }}
            </div>
            <div
              v-if="description"
              class="px-5 pt-5"
            >
              {{ description }}
            </div>
            <div class="d-flex justify-center">
              <template v-if="loadfile">
                <v-sheet
                  width="100%"
                  height="250"
                  color="#DEDEDE"
                  class="d-flex justify-center my-8"
                >
                  <v-progress-circular
                    class="align-self-center"
                    indeterminate
                    color="primary"
                  />
                </v-sheet>
              </template>
              <template v-else>
                <!-- <embed :src="file" width=800 height=500 type='application/pdf'/> -->
                <iframe
                  v-if="file && mimeType == 'pdf'"
                  :src="file"
                  class="PDFPreviewMaxSize"
                  type="application/pdf"
                />
                <img
                  v-else-if="file && mimeType == 'img'"
                  class="my-9 imagePreviewMaxSize"
                  style="max-width:100%"
                  :src="file"
                  alt=""
                >
                <video
                  v-else-if="file && mimeType == 'video'"
                  style="max-width:100%"
                  class="imagePreviewMaxSize"
                  controls
                >
                  <source
                    :src="file"
                    type="video/mp4"
                  >
                </video>
                <!-- <v-sheet v-else width="100%" height="200" color="#DEDEDE"></v-sheet> -->
              </template>
            </div>
              
            <div class="d-flex justify-center mr-2 mt-2">
              <v-btn
                class="mr-3"
                icon
                @click="getComments"
              >
                <v-icon>mdi-comment</v-icon>
              </v-btn>
              <v-btn
                class="mr-3"
                icon
              >
                <v-icon>mdi-share</v-icon>
              </v-btn>
              <v-btn
                class="mr-3"
                icon
              >
                <a
                  :download="title"
                  :href="file"
                  style="text-decoration:none;color:gray"
                >
                  <v-icon>mdi-download</v-icon>
                </a>
              </v-btn>
            </div>
          </v-card>
        </v-dialog>
      </v-fade-transition>
    </template>
    <!-- <template v-if="newtype ==='image'">
        <v-img
          :src="src"
          class="white--text align-end"
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          height="200px"
        >
        </v-img>
      </template>
      <template v-if="newtype ==='video'">
        <video
          :src="src"
          class="white--text align-end"
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          width="100%"
        >
        </video>
      </template>
      <template v-if="newtype ==='document'">
        <pdf
          :src="src"
          class="white--text align-end"
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          height="100%"
        >
        </pdf>
      </template> -->
    <div class="d-flex">
      <v-btn
        icon
        large
        class="align-self-center"
        width:200px
      >
        <v-icon class="mx-3">
          mdi-account-circle-outline
        </v-icon>
      </v-btn>
      <h5 class="align-self-center">
        {{ title }}
      </h5>
      <v-spacer />
      <h5 class="text-end pt-3 pr-3">
        {{ getFullDate(date) }}
      </h5>
    </div>
    <v-spacer />
    <div class="d-flex">
      <h5 class="pl-3 align-self-center">
        {{ description }}
      </h5>
    </div>
        

    <v-card-actions>
      <v-spacer />

      <v-btn
        icon
        @click="getComments"
      >
        <v-icon>mdi-comment</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-share-variant</v-icon>
      </v-btn>
    </v-card-actions>

    <v-dialog
      fullscreen
      :value="dialogComment"
      scrollable
      style="z-index:15"
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <v-toolbar
          flat
          dense
          dark
          color="indigo"
          style="flex:0"
        >
          <v-toolbar-title>Commentaires</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              @click="dialogComment = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="my-4">
          <v-expansion-panels
            class="mb-5"
            dark
          >
            <v-expansion-panel outlined>
              <v-expansion-panel-header color="blue-grey darken-4">
                Nouveau commentaire
              </v-expansion-panel-header>
              <v-expansion-panel-content color="blue-grey darken-4">
                <v-textarea
                  v-model="newComment"
                  counter="255"
                  label="Nouveau commentaire"
                  filled
                />
                <v-btn
                  color="success"
                  @click="addComment"
                >
                  Enregistrer
                </v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div>
            <template v-if="!loadComments">
              <template v-if="comments.length > 0">
                <div>
                  {{ comments.length > 1 ? `${comments.length} commentaires` : `${comments.length} commentaire` }} 
                </div>
                <v-card
                  v-for="comment in comments"
                  :key="comment.ID_commentaire"
                  dark
                  class="my-2"
                  color="indigo"
                >
                  <div class="px-3 pt-3">
                    {{ comment.user.lastname + ' ' + comment.user.firstname }}
                    <small class="float-right"> {{ getFullDate(comment.date) }} </small>
                  </div>
                  <v-card-text>{{ comment.commentaire }}</v-card-text>
                  <v-card-actions />
                </v-card>
              </template>
              <template v-else>
                <div
                  width="100%"
                  class="d-flex justify-center my-8"
                >
                  Aucun commentaire
                </div>
              </template>
            </template>
            <template v-else>
              <div
                width="100%"
                class="d-flex justify-center my-8"
              >
                <v-progress-circular
                  class="align-self-center"
                  indeterminate
                  color="primary"
                />
              </div>
            </template>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>

  import { getFiles, getComments, addComment } from '../http/resources'

  export default {
    props:{
      id : { type : Number, required: true },
      title : { type : String, required: true },
      description : { type : String, required: true },
      src : { type : String, required: true },
      type : { type : String, required: true },
      date : { type : String, required: true },
    },
    data(){
      return {
        mimeType : '',
        overlayImg : false,
        loadfile : false,
        file : null,
        newcounter : 0,
        newclicked : 0,
        dialogComment : false,
        comments : [],
        loadComments : false,
        newComment : '',
        pageCount : 0,
        currentPage : 0
      }
    },
    created() {
      this.getResourceFile();
      this.getMimeType(this.type)
    },
    methods:{
        getFullDate(date) {
          let newdate = new Date(date);
          const day = newdate.getDate() < 10 ? `0${newdate.getDate()}` : newdate.getDate();
          const month = newdate.getMonth() + 1 < 10 ? `0${newdate.getMonth() + 1}` : newdate.getMonth() + 1;
          const year = newdate.getFullYear();
          const FullDate = `${day}/${month}/${year}`;
          return FullDate;
        },
        async getResourceFile() {
            this.loadfile = true
            await getFiles(this.id)
              .then(async (res) => {
                await this.getMimeType(this.type);
                this.file = `data:${this.type};base64,`.concat(res.data);
                this.loadfile = false
              })
              .catch(err => console.log(err))
            this.loadfile = false
        },
        async getComments() {
          this.dialogComment = true;
          this.loadComments = true;
          
          let tabcomments = new Array()

          await getComments(this.id)
            .then(data => tabcomments = data)
            .catch(err => console.log(err));

          // Sort comments by date
          this.comments = tabcomments.sort((a, b) => new Date(b.date) - new Date(a.date)); 
          this.loadComments = false;
        },
        async addComment() {
          this.loadComments = true;
          if (!this.newComment) return;
          await addComment(this.id, this.newComment)
            .then(() => this.newComment = null)
            .catch(err => console.log(err));

          await this.getComments();
          this.loadComments = false;
        },
        getMimeType(str) {
            if (str.search(/\b(\w*pdf\w*)|(\w*PDF\w*)|(\w*application\/pdf\w*)\b/g) !== -1) this.mimeType = 'pdf'
            if (str.search(/\b(\w*image\w*)|(\w*jpg\w*)|(\w*png\w*)|(\w*jpeg\w*)|(\w*gif\w*)\b/g) !== -1) this.mimeType = 'img'
            if (str.search(/\b(\w*avi\w*)|(\w*mp4\w*)|(\w*video\w*)\b/g) !== -1) this.mimeType = 'video'
        }
    },

  }
</script>

<style lang="scss" scoped>
    .imagePreviewMaxSize {
        @media screen and (min-width: 150px) {
            max-height: 300px !important;
        }
        @media screen and (min-width: 960px) {
            max-height: 400px !important;
        }
        @media screen and (min-width: 1260px) {
            max-height: 600px !important;
        }
    }

    .PDFPreviewMaxSize {
        @media screen and (min-width: 150px) {
            height: 450px !important;
            width: 100%;
        }
        @media screen and (min-width: 960px) {
            height: 550px !important;
            width: 80%;
        }
        @media screen and (min-width: 1260px) {
            height: 600px !important;
            width: 60%;
        }
    }
</style>