import axios from 'axios';
import { getToken } from './authentication'

const url = "http://localhost:3000/api/user/actuality";

export function getActu() {
    return new Promise((resolve, reject) => {
        try {
            axios.get(url, { headers : { Authorization: `Bearer ${getToken()}` } })
                .then((response) => {
                    console.log(response);
                    resolve(response.data);
                })
                .catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}