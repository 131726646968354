import Vue from 'vue';

// Components
import App from './App.vue';

// vue session
import VueSession from 'vue-session';
Vue.use(VueSession);

// Router
import router from './router';

// Vuetify
import vuetify from './plugins/vuetify';

// Mixins
// Vue.mixin({
//   created() {
//     if(!this.$session.exists() && (this.$route.name !== 'Login' && this.$route.name !== 'Home')) this.$router.push({ name : 'Home' })
//     if(this.$session.exists() && this.$route.name === 'Login') this.$router.push({ name : 'Actuality' })
//   },
// })

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
