<template>
  <div class="container">
    <div class="large-12 medium-12 small-12 cell">
      <label>Files
        <input
          id="files"
          ref="files"
          type="file"
          multiple
          @change="handleFilesUpload()"
        >
      </label>
    </div>
    <div class="large-12 medium-12 small-12 cell">
      <div
        v-for="(file, key) in files"
        :key="key"
        class="file-listing"
      >
        {{ file }} <span
          class="remove-file"
          @click="removeFile( key )"
        >Remove</span>
      </div>
    </div>
    <br>
    <div class="large-12 medium-12 small-12 cell">
      <button @click="addFiles()">
        Add Files
      </button>
    </div>
    <br>
    <div class="large-12 medium-12 small-12 cell">
      <button @click="submitFiles()">
        Submit
      </button>
    </div>
    <div>
      <v-text-field v-model="name" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
  export default {
    /*
      Defines the data used by the component
    */
    data(){
      return {
        files: [],
        name : '',
      }
    },
    /*
      Defines the method used by the component
    */
    methods: {
      /*
        Adds a file
      */
      addFiles(){
        this.$refs.files.click();
      },
      /*
        Submits files to the server
      */
      submitFiles(){
        /*
          Initialize the form data
        */
        let formData = new FormData();
        /*
          Iteate over any file sent over appending the files
          to the form data.
        */
       formData.append('namre', 'fdgg');
        for( var i = 0; i < this.files.length; i++ ){
          let file = this.files[i];
          formData.append('logo', file);
        }

        
        /*
          Make the request to the POST /select-files URL
        */
        axios.post( '/resource/create',
          formData,
          {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          }
        ).then(function(){
          console.log('SUCCESS!!');
        })
        .catch(function(err){
          console.log('FAILURE!!' + err);
        });
      },
      /*
        Handles the uploading of files
      */
      handleFilesUpload(){
        let uploadedFiles = this.$refs.files.files;
        /*
          Adds the uploaded file to the files array
        */
        for( var i = 0; i < uploadedFiles.length; i++ ){
          this.files.push( uploadedFiles[i] );
        }
      },
      /*
        Removes a select file the user has uploaded
      */
      removeFile( key ){
        this.files.splice( key, 1 );
      }
    }
  }
</script>

<style>
  input[type="file"]{
    position: absolute;
    top: -500px;
  }
  div.file-listing{
    width: 200px;
  }
  span.remove-file{
    color: red;
    cursor: pointer;
    float: right;
  }
</style>