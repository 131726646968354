import axios from 'axios';
import { getToken } from './authentication'

const base_url = "http://localhost:3000/api/user/resources";
    

export function getResource(ID_ressource) {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`${base_url}/${ID_ressource}`, { headers : { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` } })
                .then(response => resolve(response.data))
                .catch(err => reject(err.response));
        } catch (err) {
            reject(err);
        }
    });
}

export function getResources(filter) {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`${base_url}`, { headers : { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` }, params: { isPublic : filter } })
                .then(response => resolve(response.data))
                .catch(err => reject(err.response));
        } catch (err) {
            reject(err);
        }
    });
}

export function getRessourcesCounts(filter) {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`${base_url}/folders/counts`, { headers : { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` }, params: { isPublic : filter } })
                .then(response => resolve(response.data))
                .catch(err => reject(err.response));
        } catch (err) {
            reject(err);
        }
    });
}

export function getFiles(ID_ressource) {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`${base_url}/${ID_ressource}/files`, { headers : { 'Content-Type': 'application/pdf', Authorization: `Bearer ${getToken()}` } })
                .then(response => {
                    resolve(response)
                })
                .catch(err => reject(err.response));
        } catch (err) {
            reject(err);
        }
    });
}


export function getComments(ID_ressource) {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`${base_url}/${ID_ressource}/comments`, { headers : { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` }, params: { usersToo: 'yes' } })
                .then(response => resolve(response.data))
                .catch(err => reject(err.response));
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * 
 * @param {String} nom 
 * @param {String} description 
 * @param {String} categorie 
 * @param {Array} partages 
 * @param {Boolean} isPublic 
 * @param {ArrayBuffer} file 
 * @returns 
 */
export function addResource(nom, description, categorie, groups, isPublic, file) {
    return new Promise((resolve, reject) => {
        try {
            const formData = new FormData();
            formData.append('Nom_ressource', nom)
            formData.append('Description_ressource', description)
            formData.append('ID_categorie', categorie)
            formData.append('Groups', JSON.stringify(groups))
            formData.append('isPublic', isPublic)
            formData.append('logo', file)

            // Request API
            axios.request({ method : 'post', url : `${base_url}`, headers : { 'content-type': 'multipart/form-data', Authorization: `Bearer ${getToken()}` }, data : formData })
                .then((response) => resolve(response))
                .catch(err => reject(err.response))
        } catch (err) {
            reject(err);
        }
    });
}

export function addComment(ID_ressource, commentaire) {
    return new Promise((resolve, reject) => {
        try {
            axios.request({ method : 'post', url : `${base_url}/${ID_ressource}/comments`, headers : { 'content-type': 'application/json', Authorization: `Bearer ${getToken()}` }, data : { commentaire } })
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => reject(err));
        } catch (err) {
            reject(err)
        }
    });
}