<template>
  <v-footer
    padless
    fixed
    elevation="10"
    app
  >
    <v-card
      flat
      tile
      width="100%"
      class="lighten-1 text-center"
    >
      <v-row
        class="d-flex justify-center"
        style="align-items:center"
      >
        <!-- User Connected -->
        <template v-if="sessionExist">
          <v-col cols="2">
            <v-btn
              icon
              large
              to="/Actuality"
            >
              <v-icon dark>
                mdi-newspaper-variant-outline
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn
              icon
              large
              to="/Folder"
            >
              <v-icon dark>
                mdi-folder
              </v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="auto"
            class=""
          >
            <v-btn
              class="mx-2"
              fab
              dark
              color="indigo"
              x-large
              to="/file"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn
              icon
              large
              to="/Groups"
            >
              <v-icon dark>
                mdi-account-supervisor-circle-outline
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn
              icon
              large
              to="/Profile"
            >
              <v-icon dark>
                mdi-account-circle-outline
              </v-icon>
            </v-btn>
          </v-col>
        </template>
        <!-- User not connected -->
        <template v-else>
          <v-col cols="2">
            <v-btn
              icon
              large
              to="/"
              class="my-1"
            >
              <v-icon dark>
                mdi-home-outline
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn
              icon
              large
              to="/Login"
              class="my-1"
            >
              <v-icon dark>
                mdi-account-circle-outline
              </v-icon>
            </v-btn>
          </v-col>
        </template>
      </v-row>
      <v-divider />
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name : 'Footer',
  props : {
    sessionExist : {
      type : Boolean,
      require : true
    }
  },
  data() {
    return {
      account_path : this.$session.exists() ? '/Login' : '/Profile'
    }
  },
  updated() {
    this.account_path = this.$session.exists() ? '/Login' : '/Profile';
  },
}
</script>

