import axios from 'axios';
import { getToken } from './authentication'

const base_url = "http://localhost:3000/api/user/groups";


export function getGroups() {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`${base_url}`, { headers : { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` } })
                .then(response => resolve(response.data))
                .catch(err => reject(err.response));
        } catch (err) {
            reject(err);
        }
    });
}

export function createGroups(libelle, users) {
    return new Promise((resolve, reject) => {
        try {
            // Request API
            axios.request({ method : 'post', url : `${base_url}`, headers : { 'content-type': 'application/json', Authorization: `Bearer ${getToken()}` }, data :  { libelle , users }})
                .then((response) => resolve(response))
                .catch(err => reject(err.response))
        } catch (err) {
            reject(err);
        }
    });
}