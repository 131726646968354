import axios from 'axios';
import { getToken } from './authentication'

const base_url = "http://localhost:3000/api/user/accounts"
// const base_url = "api/user/accounts"

export function getAccount(ID_utilisateur) {
    return new Promise((resolve, reject) => {
        try {
            axios.request({ url : `${base_url}/${ID_utilisateur}`, headers : { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` } })
                .then((response) => resolve(response.data))
                .catch(err => reject(err));
        } catch (err) {
            reject(err);
        }
    });
}