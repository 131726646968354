import axios from 'axios';
import { getToken } from './authentication'

const base_url = "http://localhost:3000/api/utilities/categories"

export function getCategories() {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`${base_url}`, { headers : { 'Content-Type': 'application/json', Authorization: `Bearer ${getToken()}` } })
                .then(response => resolve(response.data))
                .catch(err => reject(err.response));
        } catch (err) {
            reject(err);
        }
    });
}
