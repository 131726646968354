<template>
  <div
    class="text-center"
    style="position: fixed; top: 75%; right : 5%"
  >
    <v-dialog
      v-model="dialog"
      persistent
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mx-2"
          style="background-color:#3f51b5"
          dark
          v-bind="attrs"
          icon
          x-large
          v-on="on"
        >
          <v-icon>mdi-format-align-right</v-icon>
        </v-btn>
      </template>

      <v-card>
        <div class="my-1 d-flex justify-end">
          <v-btn
            class="mr-1"
            icon
            @click="dialog=false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-divider />
        <v-list class="overline pa-0">
          <v-list-item
            to="/Actuality"
            @click="dialog = false"
          >
            <v-list-item-action>
              <v-btn icon>
                <v-icon x-large>
                  mdi-newspaper-variant-outline
                </v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-content>
              Fil d'Actualité
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/favorite"
            @click="dialog = false"
          >
            <v-list-item-action>
              <v-btn icon>
                <v-icon x-large>
                  mdi-star-outline
                </v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-content>
              Mes Favoris
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/Download"
            @click="dialog = false"
          >
            <v-list-item-action>
              <v-btn icon>
                <v-icon x-large>
                  mdi-download-outline
                </v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-content>
              Téléchargements
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/Folder"
            @click="dialog = false"
          >
            <v-list-item-action>
              <v-btn icon>
                <v-icon x-large>
                  mdi-file-document-outline
                </v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-content>
              Mes documents
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            to="/Settings"
            @click="dialog = false"
          >
            <v-list-item-action>
              <v-btn icon>
                <v-icon x-large>
                  mdi-cog-outline
                </v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-content>
              Options
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name : 'Dialog',
    data() {
        return {
            dialog : false
        }
    },
  }
</script>