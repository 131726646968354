import Vue from 'vue';

// vue router
import VueRouter from 'vue-router';
Vue.use(VueRouter)

import Home from './views/Home.vue'
import Groups from './views/Groups.vue'
import Login from './views/Login.vue'
import Actuallity from './views/Actuallity.vue'
import Folder from './views/Folder.vue'
import FileUpload from './views/FileUpload.vue'
import Files from './views/Files.vue'
import Account from './views/Account.vue'
import Profile from './views/Profile.vue'
import AddRessource from './views/AddRessource.vue'
import Settings from './views/Settings.vue'
import Register from './views/Register.vue'

import { checkAuth, alreadyLogIn } from './mixins/checkAuth';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home // component () => import('path')
  },
  {
    path: '/Groups',
    name: 'Groups',
    component: Groups, // component () => import('path')
    beforeEnter: checkAuth
  },
  {
    path: '/FileUpload',
    name: 'FileUpload',
    component: FileUpload // component () => import('path')
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    beforeEnter: alreadyLogIn // component () => import('path')
  },
  {
    path: '/Actuality',
    name: 'Actuality',
    component: Actuallity, // component () => import('path')
    beforeEnter: checkAuth
  },
  {
    path: '/Folder',
    name: 'Folder',
    component: Folder, // component () => import('path')
    beforeEnter: checkAuth
  },
  {
    path: '/Folder/:folderName',
    name: 'Files',
    component: Files, // component () => import('path')
    beforeEnter: checkAuth
  },
  {
    path: '/Account',
    name: 'Account',
    component: Account, // component () => import('path')
    beforeEnter: checkAuth
  },
  {
    path: '/Profile',
    name: 'Profile',
    component: Profile, // component () => import('path')
    beforeEnter: checkAuth
  },
  {
    path: '/file',
    name: 'file',
    component: AddRessource, // component () => import('path')
    beforeEnter: checkAuth
  },
  {
    path: '/Settings',
    name: 'Settings',
    component: Settings, // component () => import('path')
    beforeEnter: checkAuth
  },
  {
    path: '/Register',
    name: 'Register',
    component: Register, // component () => import('path')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router;
