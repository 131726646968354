<template>
  <v-container class="layoutSize">
    <div class="ma-3">
      <div
        class="px-1 text-uppercase font-weight-bold"
        style="font-size:30pt"
      >
        GROUPES
      </div>
      <v-divider
        class="ml-1 mb-3"
        style="width: 100px;border:1px solid red; border-radius: 5px"
        color="cyan"
        inset
      />
      <v-text-field
        v-model="searchQuery"
        filled
        class="form-control pl-5"
        type="text"
        placeholder="Rechercher un groupe"
      />
      <v-row class="px-2">
        <v-col>
          <h1>Mes Groupes</h1>
        </v-col>
        <v-col align="right">
          <v-btn
            class="mx-2"
            fab
            dark
            color="red"
            small
            @click="dialogEdit = true"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
          <v-dialog
            fullscreen
            :value="dialogEdit"
            scrollable
            style="z-index:15"
            transition="dialog-bottom-transition"
          >
            <v-card tile>
              <v-toolbar
                flat
                dense
                dark
                color="indigo"
                style="flex:0"
              >
                <v-toolbar-title>Ajout d'un nouveau groupe</v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                  <v-btn
                    icon
                    @click="dialogEdit = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-text class="my-4">
                <v-text-field
                  counter="20"
                  label="Nom du groupe"
                  filled
                />
                <v-select
                  filled
                  label="Utilisateurs"
                  :items="account"
                  item-text="nom"
                  item-value="ID_utilisateur"
                  multiple
                />
                <v-btn color="success">
                  Enregistrer
                </v-btn>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-container>
        <v-list>
          <v-divider />
          <template v-for="item in resultQuery">
            <v-list-item
              :key="item.ID_groupe"
              class="text-center"
              width="100%"
              large
            >
              <v-list-item-content>
                {{ item.libelle }}
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="'div-' + item.ID_groupe" />
          </template>
        </v-list>
      </v-container>
    </div>
  </v-container>
</template>

<script>
import { getGroups } from '../http/groups'
import { getAccount } from '../http/account'

export default {
    name : 'Groups',
    data() {
        return {
        searchQuery:'',
        groups:[],
        account:[],
        dialogEdit : false,
    };
  },
  computed: {
    resultQuery (){
      if(this.searchQuery){
      return this.groups.filter((item)=>{
        return (item.libelle).search(this.searchQuery) !== -1 ? true : false;
      })
      }else{
        return this.groups;
      }
    }
  },
  created() {
      this.getAccount();
      this.getGroups();
  },
  methods: {
        async getAccount() {
            await getAccount(this.$session.get('ID_utilisateur'))
                .then((data) => this.account = data)
        },
        async getGroups() {
            await getGroups()
            .then((data) => this.groups = data)
            .catch(err => console.log(err));
        }
  }
}
</script>
