<template>
  <v-container class="layoutSize">
    <div
      class="px-4 pt-3 text-uppercase font-weight-bold"
      style="font-size:30pt"
    >
      Mes dossiers
    </div>
    <v-divider
      class="ml-4"
      style="width: 100px;border:1px solid red; border-radius: 5px"
      color="cyan"
      inset
    />
    <v-list class="pt-5 text-uppercase">
      <v-list-item>
        <v-btn
          class="font-weight-bold d-flex justify-start mb-3"
          width="100%"
          color="success"
          tile
          large
          to="/Folder/Public"
        >
          <v-icon class="mr-5">
            mdi-folder
          </v-icon>
          Public
          <v-spacer />
          {{ groups.length > 0 ? groups[0].public : null }} 
        </v-btn>
      </v-list-item>
      <v-list-item>
        <v-btn
          class="font-weight-bold d-flex justify-start mb-3"
          width="100%"
          color="primary"
          tile
          large
          to="/Folder/Share"
        >
          <v-icon class="mr-5">
            mdi-folder
          </v-icon>
          Partagé
          <v-spacer />
          {{ groups.length > 0 ? groups[0].share : null }}
        </v-btn>
      </v-list-item>
      <v-list-item>
        <v-btn
          class="font-weight-bold d-flex justify-start mb-3"
          width="100%"
          color="warning"
          tile
          large
          to="/Folder/Private"
        >
          <v-icon class="mr-5">
            mdi-folder
          </v-icon>
          Privé
          <v-spacer />
          {{ groups.length > 0 ? groups[0].private : null }}
        </v-btn>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
import { getRessourcesCounts } from '../http/resources'
  
export default {
    name : 'Folder',
    data() {
      return {
        groups : [],
      }
    },
  created() {
      this.getRessourcesCounts();
  },
    methods: {
      async getRessourcesCounts() {
        await getRessourcesCounts()
          .then((data) => this.groups = data)
          .catch(err => console.log(err));
      }
  }
}
</script>

<style lang="scss" scoped>
    .layoutSize {
        @media screen and (min-width: 960px) {
            width: 100%;
        }
        @media screen and (min-width: 1260px) {
            width: 50%;
        }
    }
</style>