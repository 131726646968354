<template>
  <!-- <div class="py-3">
    <template v-for="card in cards">
      <ActuallityComponent :key="card.title" :title="card.title" :src="card.src" :counter="card.counter" :name="card.name" :clicked="card.clicked" :type="card.type" :date="card.date" />
    </template>
  </div> -->
  <v-container
    class="px-1 mobilefirst"
    fluid
  >
    <div
      v-if="loading"
      class="d-flex justify-center"
      width="100%"
    >
      <v-progress-circular
        class="align-self-center"
        indeterminate
        color="primary"
      />
    </div>
    <template v-for="card in cards">
      <ActuallityComponent
        :id="card.ID_ressource"
        :key="card.title"
        :title="card.Nom_ressource"
        :type="card.mimetype"
        :date="card.date_upload"
        :description="card.Description_ressource"
      />
    </template>
  </v-container>
</template>

<script>
import ActuallityComponent from '../components/ActuallityComponent'
import { getActu } from '../http/actu'

export default {
  name: 'Actuallity',
  components : {
    ActuallityComponent,
  },
  data () {  
      return {
          loading : false,
          cards: [],
      }
  },
  mounted() {
      this.getResources();
  },
  methods: {
      async getResources() {
          this.loading = true
          await getActu()
            .then((res) => this.cards = res)
            .catch(err => console.log('hello' + err));
          this.loading = false
      } 
  },
};

</script>

<style lang="scss" scoped>
    .mobilefirst {
        
        @media screen and (min-width: 960px) {
            width: 100%;
        }
        @media screen and (min-width: 1260px) {
            width: 50%;
        }

    }
</style>