<template>
  <v-container
    fluid
    class="mobilefirst"
  >
    <div>
      <div
        class="px-4 mt-3 text-uppercase font-weight-bold"
        style="font-size:30pt"
      >
        Connexion
      </div>
      <v-divider
        class="ml-4 mb-3"
        style="width: 100px;border:1px solid red; border-radius: 5px"
        color="cyan"
        inset
      />
    </div>
    <div class="d-flex align-center">
      <v-card
        class="ma-2 pa-2 align-center"
        width="100%"
        elevation="0"
      >
        <v-form
          ref="form"
          lazy-validation
        >
          <v-text-field
            v-model="email"
            label="Email"
            required
          />

          <v-text-field
            v-model="password"
            type="password"
            label="Password"
            required
          />

          <v-btn
            color="primary"
            class="mr-4"
            @click="signIn"
          >
            Se connecter
          </v-btn>
          <v-btn
            depressed
            class="mr-4"
            to="Register"
          >
            Créer un compte
          </v-btn>
        </v-form>
      </v-card>
    </div>
  </v-container>
</template>

<script>
  import { signIn } from '../http/authentication'

  export default {
    name : 'Login',
    data() {
      return {
        email: 'root',
        password : 'root'
      }
    },
    methods: {
      async signIn() {
        await signIn(this.email, this.password)
          .then((response) => {
            this.$session.start();
            this.$session.set('auth_token', response.data.token);
            this.$session.set('ID_utilisateur', response.data.ID_utilisateur);
            this.$router.push({ name : 'Actuality' });
          })
          .catch((err) => console.log(err));
      },
    },
  }
</script>

<style lang="scss" scoped>
    .mobilefirst {
        
        @media screen and (min-width: 960px) {
            width: 100%;
        }
        @media screen and (min-width: 1260px) {
            width: 50%;
        }

    }
</style>