<template>
  <v-container class="my-3 layoutSize">
    <div
      class="px-4 text-uppercase font-weight-bold"
      style="font-size:25pt"
    >
      Nouvelle ressource
    </div>
    <v-divider
      class="ml-4"
      style="width: 100px;border:1px solid red; border-radius: 5px"
      color="cyan"
      inset
    />
    <v-card
      tile
      class="mb-5 mt-5"
      elevation="6"
    >
      <v-card-text class="py-4">
        <v-text-field
          v-model="nom"
          filled
          label="Nom"
          class="pt-0 mb-0"
        />
        <v-text-field
          v-model="description"
          filled
          label="Description"
          class="pt-0 mb-0"
        />
        <v-select
          v-model="selected_categorie"
          :items="categories"
          item-text="libelle"
          item-value="ID_categorie"
          label="Catégorie"
        />
        <v-select
          v-model="selected_groups"
          :items="groups"
          item-text="libelle"
          item-value="ID_groupe"
          attach
          chips
          deletable-chips
          label="Partager"
          multiple
        />
                
        <template v-if="selected_groups.length > 0">
          <v-checkbox
            v-model="isPublic"
            label="Public"
            disabled
          />
        </template>
        <template v-else>
          <v-checkbox
            v-model="isPublic"
            label="Public"
          />
        </template>

        <v-file-input
          v-model="file"
          label="Fichier"
          outlined
          dense
        />
        <v-btn
          color="success"
          @click="createResource"
        >
          Créer
        </v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { getCategories } from '../http/categories';
import { addResource } from '../http/resources';
import { getGroups } from '../http/groups'

export default {
    name : 'File',
    data() {
      return {
        nom : '',
        description : '',
        groups : [],
        selected_groups : [],
        categories : [],
        selected_categorie : null,
        isPublic : false,
        file : null,
      }
    },
    created() {
      this.getCategories();
      this.getGroups();
    },
    methods: {
      async createResource() {
        if (!this.nom && !this.categorie && (this.partages || this.isPublic) && this.file) return;

        await addResource(this.nom, this.description, this.selected_categorie, this.selected_groups, this.isPublic, this.file)
          .then(data => console.log(data.data))
          .catch(err => console.log(err));
      },
      async getCategories() {
        await getCategories()
          .then((data) => {
            return this.categories = data;
          })
          .catch(err => console.log(err)) 
      },
      async getGroups() {
        await getGroups()
          .then((data) => this.groups = data)
          .catch(err => console.log(err));
      }
    },
}

</script>

<style lang="scss" scoped>
    .layoutSize {
        @media screen and (min-width: 960px) {
            width: 100%;
        }
        @media screen and (min-width: 1260px) {
            width: 50%;
        }
    }
</style>