<template>
  <v-container
    fluid
    class="mobilefirst"
  >
    <div
      class="text-uppercase font-weight-black pa-2"
      style="text-align:center"
    >
      Bienvenue sur (re)sources Relationnelles
    </div>
    <div class="d-flex mb-5">
      <img
        height="100"
        style="margin-inline: auto"
        src="../assets/logo.png"
      >
    </div>
    <v-divider class="my-3" />
    <div class="pa-2">
      <v-card
        class="mb-4"
        color="#DEDEDE"
        outlined
        tile
        link
      >
        <div
          class="px-4 py-2 font-weight-bold text-uppercase"
          style="color:black"
        >
          Créez des ressources
        </div>
        <v-divider
          class="ml-4"
          style="width: 50px;border:1px solid red; border-radius: 5px"
          color="cyan"
          inset
        />
        <v-card-text style="color:black">
          Faîtes place à votre créativité, créez des contenues à votre images. Pour vous ou pour le reste de la communauté.
        </v-card-text>
      </v-card>
    </div>
    <div class="pa-2"> 
      <v-card
        class="mb-4"
        color="#DEDEDE"
        outlined
        tile
        link
      >
        <div
          class="px-4 py-2 font-weight-bold text-uppercase"
          style="color:black"
        >
          Partagez des ressources
        </div>
        <v-divider
          class="ml-4"
          style="width: 50px;border:1px solid red; border-radius: 5px"
          color="cyan"
          inset
        />
        <v-card-text style="color:black">
          Vos création n'attendent qu'à être découvertes ! Partagez des documents à votre famille, vos amis ou à toute la communauté.
        </v-card-text>
      </v-card>
    </div>
    <div class="pa-2">
      <v-card
        class="mb-4"
        color="#DEDEDE"
        outlined
        tile
        link
      >
        <div
          class="px-4 py-2 font-weight-bold text-uppercase"
          style="color:black"
        >
          Téléchargez des ressources
        </div>
        <v-divider
          class="ml-4"
          style="width: 50px;border:1px solid red; border-radius: 5px"
          color="cyan"
          inset
        />
        <v-card-text style="color:black">
          Téléchargez vos ressources et profitez en hors connexion !
        </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
export default {
    name : 'Home'
}
</script>


<style lang="scss" scoped>
    .mobilefirst {
        
        @media screen and (min-width: 960px) {
            width: 100%;
        }
        @media screen and (min-width: 1260px) {
            width: 50%;
        }

    }
</style>