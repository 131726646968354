import Vue from 'vue';
// import router from '../router'

export function checkAuth(to, from, next) {
    const session = Vue.prototype.$session;
    if (!session.exists()) return next({ name : 'Login'})
    next();
}

export function alreadyLogIn(to, from, next) {
    const session = Vue.prototype.$session;
    if(session.exists()) return next({ name : 'Actuality' })
    next();
}